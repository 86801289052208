<template>
    <div style="height: 100vh">
        <div id="fp">
            <!-- 1 -->
            <div class="section">
                <div class="content1" style="height: 100%">
                    <!-- 小轮播图 -->
                    <div class="swiper-container small-container" style="width: 100%; height: 100%">
                        <div class="swiper-wrapper">
                            <template v-for="el in getAdvData(43).children">
                                <div class="swiper-slide small-item" v-if="el.image" :key="el.id">
                                    <div>
                                        <p>
                                            13年历史积累
                                            <br />
                                            已服务超过320,000家企业
                                        </p>
                                        <button>
                                            <a :href="el.url" target="_blank" rel="noopener noreferrer"> 立即使用 </a>
                                        </button>
                                    </div>
                                    <img :src="el.image" />
                                </div>
                            </template>
                        </div>
                        <!-- 切换按钮 -->
                        <!-- <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div> -->
                    </div>
                </div>
            </div>
            <!-- 2 -->
            <div class="section">
                <div class="content2" style="height: 100%">
                    <div>
                        <img src="https://iv.vu818.com/img/question-icon.png" alt="" />
                        <p>你是否遇到这些问题？</p>
                        <ul>
                            <li>1、客户资料和跟进情况都记录在Excel或本子上，时间久了没法查询</li>
                            <li>2、团队中每个成员各自记录，不能同步，没法协同工作，容易撞客</li>
                            <li>3、员工离职带走客户，资料没有保留，导致客户流失严重</li>
                            <li>4、其它系统太复杂太难用了，而且非常昂贵，不能接受</li>
                        </ul>
                        <p>我们为你解决！</p>
                        <ul>
                            <li>
                                <div>
                                    <img src="https://iv.vu818.com/img/question-safe.png" alt="" />
                                    <p>完整记录 永不丢失</p>
                                    <span>完整记录所有客户资料及联系情况，永不丢失，快速查询，客户状态及跟进情况一目了然</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src="https://iv.vu818.com/img/question-safe.png" alt="" />
                                    <p>完整记录 永不丢失</p>
                                    <span>完整记录所有客户资料及联系情况，永不丢失，快速查询，客户状态及跟进情况一目了然</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src="https://iv.vu818.com/img/question-safe.png" alt="" />
                                    <p>完整记录 永不丢失</p>
                                    <span>完整记录所有客户资料及联系情况，永不丢失，快速查询，客户状态及跟进情况一目了然</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src="https://iv.vu818.com/img/question-safe.png" alt="" />
                                    <p>完整记录 永不丢失</p>
                                    <span>完整记录所有客户资料及联系情况，永不丢失，快速查询，客户状态及跟进情况一目了然</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 3 -->
            <div class="section">
                <div class="content3" style="height: 100%">
                    <div class="kong"></div>
                    <div>
                        <div>
                            <div>
                                <h2>
                                    <i></i>
                                    全渠道获客，销售线索自动入库
                                    <br />
                                    客户关系留在企业
                                </h2>
                                <div>
                                    <p v-for="(item, index) in 6" :key="index + 'p'">
                                        <b>对接广告</b>
                                        <span>通过EC汇营销，头条、抖音、威有、威有的广告线索，自动进入客户库进行管理</span>
                                    </p>
                                </div>
                                <!-- 按钮组 -->
                                <div class="bts">
                                    <button>立即咨询</button>
                                    <button>获取客户</button>
                                </div>
                            </div>
                            <img src="https://iv.vu818.com/img/p1_img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 4 -->
            <div class="section">
                <div class="content4" style="height: 100%">
                    <div>
                        <div>
                            <!-- --图片与文字组合 -->
                            <img src="https://iv.vu818.com/img/p1_img.png" alt="" />
                            <div>
                                <h2>
                                    <i></i>
                                    全渠道获客，销售线索自动入库
                                    <br />
                                    客户关系留在企业
                                </h2>
                                <div>
                                    <p v-for="(item, index) in 6" :key="index + 'p'">
                                        <b>对接广告</b>
                                        <span>通过EC汇营销，头条、抖音、威有、威有的广告线索，自动进入客户库进行管理</span>
                                    </p>
                                </div>
                                <!-- 按钮组 -->
                                <div class="bts">
                                    <button>立即咨询</button>
                                    <button>获取客户</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 5 -->
            <div class="section">
                <div class="content5" style="height: 100%">
                    <div>
                        <div>
                            <!-- --图片与文字组合 -->
                            <div>
                                <h2>
                                    <i></i>
                                    全渠道获客，销售线索自动入库
                                    <br />
                                    客户关系留在企业
                                </h2>
                                <div>
                                    <p v-for="(item, index) in 6" :key="index + 'p'">
                                        <b>对接广告</b>
                                        <span>通过EC汇营销，头条、抖音、威有、威有的广告线索，自动进入客户库进行管理</span>
                                    </p>
                                </div>
                                <!-- 按钮组 -->
                                <div class="bts">
                                    <button>立即咨询</button>
                                    <button>获取客户</button>
                                </div>
                            </div>
                            <img src="https://iv.vu818.com/img/p1_img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 6 -->
            <div class="section">
                <div class="content6" style="height: 100%">
                    <div>
                        <div>
                            <div>
                                <h2>
                                    <i></i>
                                    全渠道获客，销售线索自动入库
                                    <br />
                                    客户关系留在企业
                                </h2>
                                <div>
                                    <p v-for="(item, index) in 6" :key="index + 'p'">
                                        <b>对接广告</b>
                                        <span>通过EC汇营销，头条、抖音、威有、威有的广告线索，自动进入客户库进行管理</span>
                                    </p>
                                </div>
                                <!-- 按钮组 -->
                                <div class="bts">
                                    <button>立即咨询</button>
                                    <button>获取客户</button>
                                </div>
                            </div>
                            <img src="https://iv.vu818.com/img/p1_img.png" alt="" />
                        </div>
                    </div>
                    <div class="kong" style="bottom: 0; background-color: #e9f7f8"></div>
                </div>
            </div>
            <!-- vs图 -->
            <div class="section">
                <div class="vs" style="height: 100%"></div>
                <!-- <img src="https://iv.vu818.com/img/vs.jpg" alt="" style="width: 100%;"> -->
            </div>
            <!-- 7 -->
            <div class="section">
                <div class="content7" style="height: 100%">
                    <div>
                        <p>呼叫中心系统</p>
                        <p>
                            集成外呼系统，与CRM深度融合，一键拨号外呼，通话记录统计、通话录音、来去电弹屏、挂机短信，一站式解决，省心安心。
                        </p>
                        <button>立即使用</button>
                    </div>
                </div>
            </div>
            <!-- 8 -->
            <div class="section">
                <div class="content8" style="height: 100%">
                    <div>
                        <p>简单操作、灵活自定义</p>
                        <p>
                            简单实用，专为中小团队设计，彻底告别过去复杂难用又昂贵的内部系统。
                            <br />
                            强大且灵活的自定义功能，可以自定义出完全符合你需求的功能及界面，方便顺手。
                        </p>
                        <button>立即使用</button>
                    </div>
                </div>
            </div>
            <!-- 3d地图 -->
            <div class="section">
                <div class="map3d" style="height: 100%">
                    <div>安全合规、遍布全球的云计算基础设施</div>
                    <div>
                        <ul>
                            <li>
                                <div>
                                    <span>180</span>
                                    <span>+ Tbps</span>
                                </div>
                                <p>全网输出带宽</p>
                            </li>
                            <li>
                                <div>
                                    <span>180</span>
                                    <span>+ Tbps</span>
                                </div>
                                <p>全网输出带宽</p>
                            </li>
                            <li>
                                <div>
                                    <span>180</span>
                                    <span>+ Tbps</span>
                                </div>
                                <p>全网输出带宽</p>
                            </li>
                            <li>
                                <div>
                                    <span>180</span>
                                    <span>+ Tbps</span>
                                </div>
                                <p>全网输出带宽</p>
                            </li>
                        </ul>
                        <!-- <img src="https://iv.vu818.com/img/map.png" alt="" /> -->
                        <iframe src="https://iv.vu818.com/map" style="width: 700px; height: 400px" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
            <!-- 9 -->
            <div class="section no">
                <div class="content9" ref="content9" style="height: 100%">
                    <ul>
                        <h1>这些企业都在选择<span>威有</span></h1>
                        <li v-for="(el, index) in arr" :key="'li' + index">
                            <div v-for="eel in el" :key="eel.id" class="">
                                <div>
                                    <img :src="eel.path" alt="" />
                                </div>
                                <div></div>
                                <div></div>
                            </div>
                        </li>
                        <div class="xu"></div>
                    </ul>
                    <WebBottom></WebBottom>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import fullpage from "fullpage.js";
import WebBottom from "../../../common/WebBottom/WebBottom.vue";
import axios from "axios";
export default {
    components: { WebBottom },
    name: "TheCrm",
    inject: ["getAdvData", "changeWebBottom"],
    data() {
        return {
            dataList: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
                32,
            ],
            fp: {},
            jt: function () {},
        };
    },
    async created() {
        // this.dataList.forEach((el, i) => {
        //     el = {
        //         name: i,
        //         path: "https://iv.vu818.com/img/360.png",
        //     };
        // });
        let { data } = await axios.get("/api/project/crm");
        if (data.length < 32) {
            let num = 32 - data.length;
            for (let i = 0; i < num; i++) {
                data.push({
                    id: null,
                    logo: "",
                });
            }
        }
        this.dataList = data.map(e => {
            return {
                id: e.id,
                path: e.logo,
            };
        });
    },
    mounted() {
        this.changeWebBottom(false);
        setTimeout(() => {
            new Swiper(".small-container", {
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // },
                autoplay: true,
            });
        });
        this.fp = new fullpage("#fp", {
            navigation: true,
            navigationPosition: "left",
            navigationTooltips: [
                "一 01",
                "一 02",
                "一 03",
                "一 04",
                "一 05",
                "一 06",
                "一 07",
                "一 08",
                "一 09",
                "一 10",
                "一 11",
            ],
            paddingTop: "75px",
            normalScrollElements: ".no",
        });
        // 顶部白底
        this.$bus.$emit("alsoWhite", true);
        // 修改导航颜色
        this.$nextTick(() => {
            const navBullets = document.querySelectorAll(".fp-sr-only") || [];
            navBullets.forEach(el => {
                el.nextElementSibling.style.background = "#7781f1";
            });
        });
        // 底部
        let _this = this;
        this.jt = jt;
        function jt() {
            if (this.scrollTop <= 0) {
                _this.fp.moveSectionUp();
            }
        }
        this.$refs["content9"].addEventListener("scroll", jt, false);
    },
    beforeDestroy() {
        this.$bus.$emit("alsoWhite", false);
        this.fp.destroy("all");
        // this.$bus.$emit("changeWebBottom", true);
        this.changeWebBottom(true);
        // 移除事件监听
        window.removeEventListener("scroll", this.jt);
        console.log("nb");
    },
    computed: {
        arr() {
            // 4~11
            let arr = [];
            for (let i = 0; i < 4; i++) {
                let aa = this.dataList.map(e => e);
                switch (i) {
                    case 0:
                        aa = aa.slice(0, 8);
                        arr.push([{}, {}, {}, ...aa, {}, {}, {}]);

                        break;
                    case 1:
                        aa = aa.slice(8, 17);
                        arr.push([{}, {}, {}, ...aa, {}, {}]);

                        break;
                    case 2:
                        aa = aa.slice(17, 25);
                        arr.push([{}, {}, {}, ...aa, {}, {}, {}]);

                        break;
                    case 3:
                        aa = aa.slice(25, 32);
                        arr.push([{}, {}, {}, {}, ...aa, {}, {}, {}]);

                        break;
                }
            }
            return arr;
        },
    },
};
</script>

<style scoped lang="less">
.swiper-slide {
    width: 100%;
    height: 100%;
    // overflow: hidden;
}
.content1 {
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .small-item {
        position: relative;
        & > div {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            P {
                width: 560px;
                height: 200px;
                font-size: 45px;
                color: #fff;
                font-family: 微软雅黑 Light;
                text-align: center;
                line-height: 80px;
            }

            button {
                width: 174px;
                height: 50px;
                font-size: 30px;
                text-align: center;
                line-height: 50px;
                border-radius: 10px;
                border: solid 1px #ffffff;
                font-family: 微软雅黑 Light;
                color: #ffffff;
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #ffffff;
                }
            }
        }
    }
}
.content2 {
    position: relative;
    background-color: #19191b;
    background-image: url("https://iv.vu818.com/img/question-bg1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    & > div {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(5, 5, 5, 0.8);
        // padding-top: 20px;
        & > ul:nth-of-type(1) {
            display: flex;
            li {
                display: inline-block;
                width: 260px;
                padding-right: 44px;
                font-size: 14px;
                color: #ffffff;
                font-family: Microsoft YaHei;
                line-height: 32px;
                opacity: 0.6;
            }
        }
        & > p {
            text-align: center;
            font-size: 30px;
            color: #ffffff;
            font-family: Microsoft YaHei;
            line-height: 80px;
        }
        & > p:nth-of-type(2) {
            // padding-top: 50px;
            font-size: 40px;
        }

        & > ul:nth-of-type(2) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            li {
                width: 50%;
            }
            li > div {
                width: 350px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 10px;
                padding-top: 10px;
                p {
                    text-align: center;
                    font-size: 20px;
                    color: #ffffff;
                    font-family: Microsoft YaHei;
                    line-height: 40px;
                }
                span {
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 32px;
                    opacity: 0.7;
                }
            }
        }
    }
}
.content3,
.content4,
.content5,
.content6 {
    background-color: #3829cc;
    position: relative;
    & > div {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        z-index: 9;
        & > div {
            margin: 0px;
            line-height: 1.8;
            font-size: 16px;
            padding: 88px 64px;
            background-color: #4637e5;
            -webkit-border-radius: 32px;
            -moz-border-radius: 32px;
            border-radius: 32px;
            overflow: hidden;
            // 大框
            display: flex;
            color: white;
            & > div {
                width: 45%;
                h2 {
                    font-size: 36px;
                    position: relative;
                    z-index: 1;
                    font-family: inherit;
                    font-weight: 700;
                    line-height: 1.1;
                    color: inherit;
                    padding-bottom: 40px;
                    i {
                        display: block;
                        position: absolute;
                        z-index: -1;
                        left: -16px;
                        top: -15px;
                        width: 48px;
                        height: 48px;
                        border-radius: 56px;
                        background-color: rgb(20, 191, 87);
                    }
                }
                & > div {
                    p {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        font-size: 18px;
                        padding: 0 0 18px 0;
                        b {
                            display: inline-block;
                            width: auto;
                            text-align: center;
                            min-width: 100px;
                            height: 40px;
                            line-height: 36px;
                            font-size: 16px;
                            padding: 0 4px;
                            border: 2px solid #fff;
                            border-radius: 8px;
                        }
                        span {
                            padding-left: 10px;
                            height: 40px;
                            font-size: 16px;
                            line-height: 1.2;
                            opacity: 0.8;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                // 按钮组
                .bts {
                    button {
                        display: inline-block;
                        font-size: 18px;
                        text-align: center;
                        line-height: 48px;
                        -webkit-border-radius: 64px;
                        -moz-border-radius: 64px;
                        border-radius: 64px;
                        -webkit-transition: all 0.3s;
                        -moz-transition: all 0.3s;
                        transition: all 0.3s;
                        border: 2px solid #fff;
                        color: #fff;
                        background: transparent;
                        padding: 0 40px;
                        margin: 0 8px;
                        cursor: pointer;
                    }
                    button:hover {
                        opacity: 0.8;
                    }
                    button:nth-last-of-type(1) {
                        border-color: #0fba32;
                        background-color: #0fba32;
                    }
                }
            }
            & > img {
                max-width: 55%;
            }
        }
    }
    .kong {
        position: absolute;
        background-color: white;
        height: 100px;
        width: 100%;
        z-index: 0;
        left: 0;
    }
}
.vs {
    background-color: #e9f7f8;
    background-image: url("https://iv.vu818.com/img/vs.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}
.content7 {
    background-color: white;
    position: relative;
    background: #f9fbfd url("https://iv.vu818.com/img/content4-22.png") no-repeat right;
    & > div {
        position: absolute;
        top: 50%;
        left: 100px;
        transform: translate(0, -50%);
        text-align: center;
        P:nth-of-type(1) {
            width: 700px;
            height: 48px;
            font-size: 48px;
            color: #595959;
            font-family: 微软雅黑 Light;
            text-align: center;
        }
        P:nth-of-type(2) {
            width: 700px;
            font-size: 18px;
            color: #595959;
            padding-top: 40px;
            padding-bottom: 40px;
            font-family: 微软雅黑 Light;
            text-align: center;
            line-height: 30px;
        }
        button {
            width: 164px;
            height: 50px;
            font-size: 30px;
            text-align: center;
            line-height: 50px;
            border-radius: 10px;
            border: solid 1px #595959;
            font-family: 微软雅黑 Light;
            color: #595959;
            background-color: rgba(255, 255, 255, 0);
            cursor: pointer;
        }
    }
}
.content8 {
    background-color: #0e0e0e;
    position: relative;
    background: #0e0e0e url("https://iv.vu818.com/img/content2.png") no-repeat bottom;
    & > div {
        position: absolute;
        left: 50%;
        top: 100px;
        transform: translate(-50%);
        text-align: center;
        p:nth-of-type(1) {
            width: 700px;
            height: 48px;
            font-size: 48px;
            color: #fff;
            font-family: 微软雅黑 Light;
            text-align: center;
        }
        p:nth-of-type(2) {
            width: 700px;
            font-size: 18px;
            color: #fff;
            padding-top: 40px;
            padding-bottom: 40px;
            font-family: 微软雅黑 Light;
            text-align: center;
            line-height: 30px;
        }
        button {
            width: 164px;
            height: 50px;
            font-size: 30px;
            text-align: center;
            line-height: 50px;
            border-radius: 10px;
            border: solid 1px #ffffff;
            background-color: rgba(255, 255, 255, 0);
            cursor: pointer;
            color: #fff;
        }
    }
}
.map3d {
    & > div:nth-of-type(1) {
        font-size: 34px;
        line-height: 58px;
        font-weight: 700;
        color: #181818;
        padding: 40px 24px;
        position: relative;
        z-index: 1;
        pointer-events: none;
        text-align: center;
    }
    & > div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        & > ul {
            width: 400px;
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
                padding: 18px 24px;
                span:nth-of-type(1) {
                    font-size: 44px;
                    margin-bottom: 4px;
                    font-weight: 700;
                }
                span:nth-of-type(2) {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0;
                    line-height: 22px;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    color: #737373;
                    letter-spacing: 0;
                    line-height: 24px;
                }
            }
        }
    }
}
.content9 {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    h1 {
        padding: 64px 0 82px 0;
        text-align: center;
        line-height: 1.2;
        font-size: 48px;
        span {
            color: #7781f1;
        }
    }
    & > ul {
        // margin-top: 50px;
        // margin-bottom: 100px;
        background-color: #eef4f4;
        padding-bottom: 50px;
        position: relative;
        max-width: 1440px;
        overflow: hidden;
        margin: 0 auto;
        & > li:nth-of-type(even) {
            margin: 75px 0px;
            margin-left: -55px;
        }
        & > li {
            white-space: nowrap;
            & > div {
                display: inline-block;
                margin: 0 5px;
                position: relative;
                width: 99px;
                & > div {
                    border: 1px solid white;
                    width: 95px;
                    height: 56px;
                    position: absolute;
                    border-radius: 5px;
                    background-color: white;
                }
                & > div:nth-of-type(1) {
                    img {
                        width: 100%;
                    }
                    z-index: 9;
                }
                & > div:nth-of-type(2) {
                    transform: rotate(60deg);
                }
                & > div:nth-of-type(3) {
                    transform: rotate(-60deg);
                }
            }
        }
    }

    .xu {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}
</style>
