import { render, staticRenderFns } from "./TheCRM.vue?vue&type=template&id=adfb9ab2&scoped=true&"
import script from "./TheCRM.vue?vue&type=script&lang=js&"
export * from "./TheCRM.vue?vue&type=script&lang=js&"
import style0 from "./TheCRM.vue?vue&type=style&index=0&id=adfb9ab2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adfb9ab2",
  null
  
)

export default component.exports